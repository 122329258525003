export const webinarFields = [
  {
    name: "firstName",
    type: "text",
    label: "First name*",
    require: true,
    placeholder: " ",
  },
  {
    name: "lastName",
    type: "text",
    label: "Last name*",
    require: true,
    placeholder: " ",
  },
  {
    name: "email",
    type: "email",
    label: "Email*",
    require: true,
    placeholder: " ",
  },
  {
    name: "phone",
    type: "text",
    label: "Phone",
    require: false,
    placeholder: " ",
  },
  {
    name: "company",
    type: "text",
    label: "Company",
    require: false,
    placeholder: " ",
  },
];
